import React, { useEffect, useState } from "react";
import Banner from "../components/banner";

import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";

import $ from 'jquery';

import BlogsCards from "../components/blogs_cards";

import BannerMobile from "../components/banner_mobile";

import CardContacto from "../components/card_contacto";

import ListaPromociones from "../components/lista_promociones";
import * as app from "../fetch/fetchApi";
import ImagenPromociones from "../components/imagen_promociones";
import CardContactoMobile from "../components/card_contacto_mobile";


const PlantillaPromociones = () => {
  const isBrowser = typeof window !== "undefined"
  let width;
  console.log = function () { };

  /**
   let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosNodo = this.state.dataNodo
          let datosComplementa = this.state.dataComplementa
          let datosMenuFooter = this.state.dataMenuFooter
          let datosPrecios = this.state.dataPrecio
          let datosBlog=this.state.dataBlog
          let datosTarifas = this.state.dataTarifas
   */


  const [datosMenu, setDataMenu] = useState({})

  useEffect(() => {
    // get data from GitHub api

    fetch(app.url + `/node-api/m22_item_menu/main-menu`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenu(resultData)
      })
    // set data for the number of stars
  }, [])
  const [datosNodo, setDataNodo] = useState([])
  const [datosPrecios, setDataPrecios] = useState([])

  useEffect(() => {

    // get data from GitHub api

    /*
    fetch(app.url+"/node-api/m22_get_contenido_node/2158",{
      method:'GET',
      headers:{'Content-type':'application/json'},
    })*/
    var data = { 'path': 'atracciones/hospedaje/hotel' }
    fetch(app.url + "/node-api/m22_get_content_node/", {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(data, null, 4)
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataNodo(resultData)

      })
    // set data for the number of stars
  }, [])

  const [datosMenuFooter, setDataMenuFooter] = useState({})
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenuFooter(resultData)
      })
    // set data for the number of stars
  }, [])


  const [datosBlogCard, setDataBlogCard] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/views/prueba_get_card_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlogCard(resultData)
      })
    // set data for the number of stars
  }, [])

  const [datosPromo, setDataPromo] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/views/prueba_get_promociones?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataPromo(resultData)
      })
    // set data for the number of stars
  }, [])


  const [datosReglamento, setDataReglamento] = useState([])

  useEffect(() => {

    // get data from GitHub api
    if (isBrowser) {
      console.log(window.location.pathname)
      let id = window.location.pathname
      id = id.substring(1);
      var data = { 'path': id }
      console.log(JSON.stringify(data, null, 4))
      fetch(app.url + "/node-api/m22_get_content_node/", {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data, null, 4)
      })
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          console.log(resultData)
          setDataReglamento(resultData)
        })
      // set data for the number of stars
    }
  }, [])


  if (isBrowser) {
    width = window.innerWidth
    console.log(width)
    setTimeout(() => {
      $('.tituloAyudamos').css('margin-top', 'unset');
      $('.tituloAyudamos').css('padding-top', 'unset');
      $('.divContacto').css('margin-top', 'unset');
    }, 2000);


  }

  return (

    <div>
      {width < 1100 ? <Banner data={datosMenu} /> : <BannerMobile data={datosMenu} />
      }
      {datosNodo.field_imagen_inicial_sitio !== undefined ? <ImagenPromociones data={datosNodo} /> : ''}

      <div className="row px-xl-0 px-md-5 px-4 justify-content-evenly" style={{ maxWidth: 'unset', marginTop: '58px' }}>
        <div className=" col-12 col-xl-7 px-0">
          <h3 className="tituloInteres">Promociones</h3>
          <p className="labelNormal">¡Disfruta del paraíso con estas promociones! Obtén descuentos en tus entradas y en los eventos de interés para ti y tus amigos o familiares. Las Estacas te espera para celebrar contigo.</p>
          <ListaPromociones data={datosPromo} />
          {/*<CardPromociones data={datosPromo} />*/}

        </div>

        <div className="col-12 col-xl-3 pe-3 ps-0">
          <div className="d-block d-md-none d-xl-block ">
            <CardContacto />
          </div>
          <div className="mt-0 d-none d-md-block d-xl-none">
            <CardContactoMobile />
          </div>
        </div>

      </div>
      {datosBlogCard.length !== 0 ? <BlogsCards data={datosBlogCard} /> : ''}


      <footer className={'l-footer panel'} role={'contentinfo'}>
        <div className={'footer'}>
          <NewsLetter />
          <MenuFooter data={datosMenuFooter} />

          <Politicas />
          <Redes />
        </div>
        <Copyright />
      </footer>
    </div>
  );
};

export default PlantillaPromociones;